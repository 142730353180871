<template>
  <div class="country-region-selection">
    <div class="countryheader">
      <a
        v-for="country in countries"
        :key="country.key"
        href="#"
        class="countryfilter"
        :class="{ active: activeCountry === country.key }"
        @click.prevent="setActiveCountry(country.key)"
      >{{ country.label }}</a>
    </div>
    <div class="countryheader-mobile">
      <select
        :value="changeCountry"
        @change="setActiveCountrySwitcher($event)"
        class="mobile-country-selection"
      >
        <option
          v-for="country in countries"
          :key="country.key"
          :value="country.key"
          :class="{ active: activeCountry === country.key }"
        >{{ country.label }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countries: Array,
    activeCountry: String,
    changeCountry: String
  },
  methods: {
    setActiveCountrySwitcher(event) {
      this.$emit('setActiveCountry', event.target.value);
    },
    setActiveCountry(country) {
      this.$emit('setActiveCountry', country);
    }
  }
};
</script>
