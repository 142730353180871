<template>
  <footer class="footer">
    <div>
      Talk Online Panel GmbH
      <br />Karlsgasse 7/Top 5, 1040 Vienna, Austria
      <br />
      <a href="mailto:rfq@talkonlinepanel.com">rfq@talkonlinepanel.com</a>
    </div>
    <div>
      <a
        href="http://b2b.talkonlinepanel.com/gb/esomar-28-questions?source=covid19watch"
        target="_blank"
      >ESOMAR 28</a>
      <br />
      <a href="https://talkonlinepanel.com/gb/imprint?source=covid19watch" target="_blank">Imprint</a>
      <br />
      <a
        href="https://talkonlinepanel.com/gb/PP-20180521?source=covid19watch"
        target="_blank"
      >Privacy Policy</a>
    </div>
    <div class="copyright">
      <router-link to="/methodology">Methodology</router-link>
      <br />
      <router-link to="/partner">Information for Media partners</router-link>
      <br />
      &copy; {{ copyrightDate }}
      <a
        class="copyright-link"
        href="https://talkonlinepanel.com?source=covid19watch"
        target="_blank"
        rel="noopener"
      >Talk Online Panel GmbH</a>
    </div>
    <div class="cookieconsent active" v-if="!cookieConsent">
      We use cookies such as Google Analytics in order to offer visitors a better browsing experience and
      analyse website traffic. For more information on the Google Analytics cookies and how to control them
      please visit: (Cookie Policy)
      <br />
      <button @click="acceptCookieConsent()">Accept</button>
      <a
        href="https://talkonlinepanel.com/gb/CP-20180521?source=covid19watch"
        target="_blank"
        rel="noopener"
      >Cookie Policy</a>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return { cookieConsent: true };
  },
  computed: {
    copyrightDate() {
      return new Date().getFullYear();
    }
  },
  methods: {
    acceptCookieConsent() {
      this.cookieConsent = true;
      document.cookie = 'acceptCookies=true;';

      this.loadGTag();
    },
    loadGTag() {
      const script = document.createElement('script');

      script.onload = () => {
        // Now you can use JWPlayer in here
      };

      script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-162530651-1';
      script.async = true;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'UA-162530651-1');
    },
    acceptedCookieConsent() {
      const acceptCookies = document.cookie.split('; ').find(cookie => cookie.indexOf('acceptCookies') === 0);
      if (!acceptCookies) return false;
      return acceptCookies.split('=')[1] === 'true';
    }
  },
  created() {
    setTimeout(() => {
      this.cookieConsent = this.acceptedCookieConsent();

      if (this.cookieConsent) {
        this.loadGTag();
      }
    }, 500);
  }
};
</script>
