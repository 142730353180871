<template>
  <section>
    <div
      class="maparea-info"
      :class="{ active: activeCountry !== 'EUROPE' && !countryMapAvailable }"
    >No map available for this region. Please scroll down to see detailed results.</div>
    <div class="maparea">
      <highcharts
        ref="mapChart"
        v-for="options in mapOptions"
        :key="options.rnd"
        :constructor-type="'mapChart'"
        :options="options"
        class="map"
      ></highcharts>
      <div v-if="!!dataBySymptom" class="country-ranking">
        <div class="country-ranking-heading">Countryranking</div>
        <div v-for="item in dataBySymptom" :key="item.country" class="country-by-symptom">
          <span>{{ trans.countryList[item.country] || item.country }}</span>
          <span class="country-by-symptom-percentage">{{ item.value }} %</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Highcharts from 'highcharts';
import trans from '~/src/translations/en.json';
export default {
  components: {},
  props: {
    mapData: Array,
    currentFiltering: String,
    mapName: String,
    mapCredits: String,
    activeCountry: String,
    countryMapAvailable: Boolean,
    dataBySymptom: Array
  },
  data() {
    return {
      trans,
      usedMapData: null,
      mapOptions: this.loadMapOptions(this.mapData, 'europe', this.currentFiltering, this.mapCredits)
    };
  },
  watch: {
    mapData(mapData) {
      this.mapOptions = this.loadMapOptions(mapData, this.mapName, this.currentFiltering, this.mapCredits);
      return;
      let { chart } = this.$refs.mapChart;
      chart.update({
        series: [
          {
            name: '',
            states: {
              hover: {
                color: '#04a1f5'
              }
            },
            dataLabels: {
              enabled: true,
              format: '{point.label}'
            },
            allAreas: true,
            data: mapData
          }
        ]
      });
    },
    mapName(country) {
      if (country == 'europe') {
        import(`~/src/geo/${country}.geo.json`).then(data => {
          Highcharts.maps[country] = data;
          this.usedMapName = country;
          this.mapOptions = this.loadMapOptions(
            this.mapData,
            country,
            this.currentFiltering,
            this.mapCredits
          );
        });
      } else {
        import(`~/src/geo/${country}-all.geo.json`).then(data => {
          Highcharts.maps[country] = data;
          this.usedMapName = country;
          this.mapOptions = this.loadMapOptions(
            this.mapData,
            country,
            this.currentFiltering,
            this.mapCredits
          );
        });
      }
    }
  },
  methods: {
    loadMapOptions(mapData, map, currentFiltering, mapCredits) {
      return [
        {
          rnd: Math.random(),
          chart: {
            map,
            animation: false,
            events: {
              drilldown: e => {
                const country = e.point['hc-key'];
                this.$emit('switchCountry', country);
              },
              load: function(e) {
                if (map === 'europe')
                  this.mapZoom(
                    0.7,
                    this.xAxis[0].toValue(window.innerWidth > 700 ? 600 : 190),
                    this.yAxis[0].toValue(500),
                    0,
                    100
                  );
              }
            }
          },
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          plotOptions: { map: { animation: 0 } },
          legend: false,
          colorAxis: !!currentFiltering
            ? {
                min: 0,
                minColor: '#ede9ce',
                maxColor: '#2782a0',
                dataClasses: [
                  { color: '#EDE9CE', from: 0, to: 0.09 },
                  { color: '#E5DCA8', from: 0.1, to: 0.199 },
                  { color: '#E2D48D', from: 0.2, to: 0.299 },
                  { color: '#DDDB8E', from: 0.3, to: 0.399 },
                  { color: '#BAC98B', from: 0.4, to: 0.599 },
                  { color: '#B2BA90', from: 0.6, to: 0.799 },
                  { color: '#BDD3B6', from: 0.8, to: 0.999 },
                  { color: '#CEE8CA', from: 1, to: 1.999 },
                  { color: '#CAEADE', from: 2, to: 2.999 },
                  { color: '#AFDDD0', from: 3, to: 3.999 },
                  { color: '#A2CCC2', from: 4, to: 5.999 },
                  { color: '#9AC1B9', from: 6, to: 8.999 },
                  { color: '#8AA8A3', from: 9, to: 12.999 },
                  { color: '#7EA59D', from: 13, to: 18.999 },
                  { color: '#7DA08E', from: 19, to: 24.999 },
                  { color: '#368975', from: 25, to: 39.999 },
                  { color: '#2F8E80', from: 40, to: 64.999 },
                  { color: '#4F8E8C', from: 65, to: 79.999 },
                  { color: '#459696', from: 80, to: 89.999 },
                  { color: '#599996', from: 90, to: 93.999 },
                  { color: '#5C9399', from: 94, to: 96.999 },
                  { color: '#3D8E8C', from: 97, to: 100 }
                ]
              }
            : {
                min: 0,
                minColor: '#DDDDDD',
                maxColor: '#DDDDDD'
              },
          series: [
            {
              name: '',
              states: {
                hover: {
                  color: '#A5C5C3'
                }
              },
              dataLabels: !!currentFiltering
                ? {
                    enabled: true,
                    format: `<span style="font-family:'Open Sans',sans-serif;font-weight:100;font-size:12px;">{point.value:.2f} %</span>`,
                    useHTML: true,
                    nullFormat: this.mapName === 'europe' ? '' : '0.00%'
                  }
                : {},
              allAreas: true,
              nullInteraction: true,
              data: mapData
            }
          ],
          credits: {
            enabled: true,
            href: 'https://covid19watch.net',
            text: `Source: covid19watch.net, CAWI, Date: ${new Date().getDate()}. ${
              trans.months[new Date().getMonth()]
            } ${new Date().getFullYear()}, Data: ${mapCredits || 'Europe'}`,
            mapTextFull: ' ',
            mapText: ' '
          },
          tooltip: {
            backgroundColor: '#007872',
            borderWidth: 0,
            headerFormat: undefined,
            pointFormat: `<div style="font-family:'Open Sans',sans-serif;font-weight:100;"><div style="text-align:center;text-transform:uppercase;line-height:2rem;">{point.name}</div>{point.label}<div style="text-align:right;font-size:.8rem"><br/>Talk Online Panel<br />covid19watch.net</div></div>`,
            nullFormat: `<div style="font-family:'Open Sans',sans-serif;font-weight:100;text-transform:uppercase;font-size:.8rem">not enough data</div>`,
            padding: 16,
            shadow: false,
            useHTML: true,
            style: {
              color: '#fff',
              fontSize: '1.1rem',
              textAlign: 'center'
            }
          }
        }
      ];
    }
  }
};
</script>
