<template>
  <div v-if="!!scenario">
    <SymptomFilters
      v-if="!!config && !!scenario"
      :filteredByMatrixRow="filteredByMatrixRow"
      :matrixRows="scenario.filterOptions.matrix_rows"
      @toggleMatrixRow="toggleMatrixRow"
    />
    <section class="filters">
      <SeverityFilters
        v-if="!!scenario"
        :matrixColumns="scenario.filterOptions.matrix_columns"
        :filteredByMatrixRow="filteredByMatrixRow"
        :filteredByMatrixColumn="filteredByMatrixColumn"
        @toggleMatrixColumn="toggleMatrixColumn"
      />
    </section>
    <section v-if="!!dataBySymptom">
      <div class="country-by-symptom">
        <span>Country</span>
        <span>Total</span>
      </div>
      <div v-for="item in dataBySymptom" :key="item.country" class="country-by-symptom">
        <span>{{ trans.countryList[item.country] || item.country }}</span>
        <span>{{ item.value }} %</span>
      </div>
    </section>
    <SurveyList v-if="!!config && !!mapping" :mapping="mapping" :config="config" />
    <Hero />
    <Partners />
    <About />
  </div>
</template>

<script>
import SymptomFilters from '~/src/components/SymptomFilters';
import SeverityFilters from '~/src/components/SeverityFilters';
import Hero from '~/src/components/Hero';
import DateFilter from '~/src/components/DateFilter';
import SurveyList from '~/src/components/SurveyList';
import Partners from '~/src/components/Partners';
import About from '~/src/components/About';

import { aggregateDataBySymptom } from '~/utils/helpers';
import { IMAGE_ROOT, LANG_CODE, CLOUDFRONT_ID, AGGREGATED_DATA } from '~/utils/constants';
import trans from '~/src/translations/en.json';

export default {
  components: {
    Hero,
    DateFilter,
    SeverityFilters,
    SymptomFilters,
    SurveyList,
    Partners,
    About
  },
  props: {
    config: Object,
    mapping: Object,
    scenario: Object
  },
  data() {
    return {
      IMAGE_ROOT,
      LANG_CODE,
      trans,
      data: null,
      countryData: {},
      datefilter: 'all',
      dateframe: null,
      currentQuestion: null,
      filteredByMatrixRow: null,
      filteredByMatrixColumn: -1,
      filterRowLoopIndex: 0,
      autoplay: null
    };
  },
  computed: {
    dataBySymptom() {
      return aggregateDataBySymptom(
        this.filteredByMatrixRow,
        this.data,
        this.config,
        this.mapping,
        this.scenario,
        this.activeCountry,
        this.filteredByMatrixColumn,
        this.datefilter
      );
    }
  },
  methods: {
    getData() {
      fetch(`https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/aggregatedCounts.json`)
        .then(r => r.json())
        .then(data => {
          this.countryData['EUROPE'] = data.all;
        });
      fetch(
        `https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/aggregatedCountsByScenario.json`
      )
        .then(r => r.json())
        .then(data => {
          this.data = data.all;
        })
        .catch(console.error);
    },
    setDateFilter(filter) {
      this.datefilter = filter;
      this.switchPath(this.activeCountry);
    },
    loadCountryData(country) {
      if (country === 'EUROPE') {
        this.activeCountry = country;
      } else if (!this.countryData[country]) {
        fetch(
          `https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/province/${country}.json`
        )
          .then(r => r.json())
          .then(data => {
            this.countryData[country] = data.all;
            this.activeCountry = country;
            this.activeRegion = '';
            if (this.canDrillDown(country)) {
              this.mapName = country;
            } else {
              this.mapName = 'europe';
            }
          })
          .catch(data => {
            this.activeCountry = country;
            this.activeRegion = '';
            if (this.canDrillDown(country)) {
              this.mapName = country;
            } else {
              this.mapName = 'europe';
            }
          });
      } else {
        this.activeCountry = country;
        this.activeRegion = '';
        if (this.canDrillDown(country)) {
          this.mapName = country;
        } else {
          this.mapName = 'europe';
        }
      }
    },
    storeMapping(mapping) {
      this.mapping = mapping;
    },
    storeConfig(config) {
      this.config = config;
    },
    canDrillDown(country) {
      return (
        !!this.mapping &&
        this.mapping.province[country] !== undefined &&
        this.mapping.province[country][0].drilldownEnabled
      );
    },
    toggleMatrixRow({ questionIndex, rowKey, loopIndex }, notCancel) {
      if (
        !!this.filteredByMatrixRow &&
        this.filteredByMatrixRow.questionIndex === questionIndex &&
        this.filteredByMatrixRow.rowKey === rowKey
      ) {
        this.filteredByMatrixRow = null;
        this.filteredByMatrixColumn = null;
      } else {
        this.filteredBySwitch = null;
        this.filteredByMatrixColumn = this.filteredByMatrixColumn || -1;
        this.filteredByMatrixRow = { questionIndex, rowKey, index: loopIndex + 1 };
      }
      if (notCancel) return;

      this.cancelAutoplay();
    },
    toggleMatrixColumn(column) {
      this.filteredByMatrixColumn = column;

      this.cancelAutoplay();
    },
    switchPath(country) {
      const datePath = this.datefilter !== 'all' ? `/time/${this.datefilter}` : '';
      let path = `${datePath}` || '/';

      this.$router.push({ path });
    },
    cancelAutoplay() {
      if (!this.autoplay) return;
      clearInterval(this.autoplay);
    }
  },
  created() {
    if (!!this.$route.params && !!this.$route.params.timeframe) {
      this.datefilter = this.$route.params.timeframe;
    }

    this.getData();

    this.toggleMatrixRow({
      questionIndex: this.scenario.filterOptions.matrix_rows[this.filterRowLoopIndex].idx,
      rowKey: this.scenario.filterOptions.matrix_rows[this.filterRowLoopIndex].value,
      loopIndex: this.filterRowLoopIndex
    });

    if (!!this.$route.query && !!this.$route.query.autoplay) {
      const interval = isNaN(this.$route.query.autoplay) ? 4000 : this.$route.query.autoplay;
      this.autoplay = setInterval(() => {
        this.filterRowLoopIndex += 1;
        if (this.filterRowLoopIndex === this.scenario.filterOptions.matrix_rows.length) {
          this.filterRowLoopIndex = 0;
        }

        this.toggleMatrixRow(
          {
            questionIndex: this.scenario.filterOptions.matrix_rows[this.filterRowLoopIndex].idx,
            rowKey: this.scenario.filterOptions.matrix_rows[this.filterRowLoopIndex].value,
            loopIndex: this.filterRowLoopIndex
          },
          true
        );
      }, interval);
    }
  }
};
</script>
