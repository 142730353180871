/* eslint-disable */

export const BRANCH_PREFIX = process.env.BRANCH_PREFIX || '';
export const IMAGE_ROOT = `${process.env.BRANCH_PREFIX}/images` || '/images';
export const ENV = process.env.NODE_ENV || 'local';
export const API_PATH = process.env.API_PATH || 'https://pam-api-dev.reppublika.com';
export const LANG_CODE = process.env.LANG_CODE || 'en';

export const PANEL = process.env.VUE_APP_PANEL || 'talk';
export const CLOUDFRONT_ID = process.env.CLOUDFRONT_ID || 'dlpx5qlu62kvc';
export const AGGREGATED_DATA = 'aggregatedDataNew';
