<template>
  <section class="bepart">
    <h2
      class="bepart-heading"
    >You too can take part in the survey - for your country, in your language</h2>
    <p class="bepart-text">Click on your country to participate in the survey</p>
    <div class="bepartlinks">
      <template v-for="(survey, index) in surveyList">
        <a
          :key="survey.key"
          :href="`${survey.link}`"
          target="_blank"
          class="bepartlink"
          rel="noopener"
          :class="{ [`link-${survey.country.toLowerCase()}`]: true }"
        >{{ survey.label }}</a>
      </template>
    </div>
  </section>
</template>

<script>
import trans from '~/src/translations/en.json';
export default {
  data() {
    return { trans };
  },
  props: {
    mapping: Object,
    config: Object
  },
  computed: {
    surveyList() {
      const surveys = [];
      Object.keys(this.trans.countryList).forEach(country => {
        if (country === 'EUROPE' || country === 'RU') return;

        if (!!this.mapping.languages[country]) {
          Object.keys(this.mapping.languages[country]).forEach(lang => {
            let key = `${country}-${this.mapping.languages[country][lang].language.toUpperCase()}`;
            let link = `https://covid19watch.net/survey/${country.toUpperCase()}/${
              this.mapping.languages[country][lang].postfix
            }`;
            surveys.push({
              key: `${key}`,
              label: `${this.trans.countryListLocal[key].toUpperCase()}`,
              country: `${country}`,
              lang: this.mapping.languages[country][lang].language,
              link: link
            });
          });
        } else {
          if (!!this.config.scenariosPerCountry[country]) {
            let link = `https://covid19watch.net/survey/${country.toUpperCase()}`;
            surveys.push({
              key: `${country}`,
              label: this.trans.countryListLocal[country].toUpperCase(),
              country: `${country}`,
              lang: null,
              link: link
            });
          }
        }
      });
      return surveys;
    }
  }
};
</script>
