<template>
  <section class="content-section">
    <div v-if="!!element.title" class="content-header">
      <h2 class="content-headline">{{ element.title }}</h2>
    </div>
    <div v-if="!!element.text" class="content-text" v-html="element.text" />
    <div v-if="!!element.subElements">
      <template v-for="(subElement) in element.subElements">
        <div
          v-if="!!subElement.title"
          class="subcontent-header"
          :key="`${index}_el1_${subElement.title}_${subElement.text}`"
        >
          <h2 class="subcontent-headline" v-html="subElement.title"></h2>
        </div>
        <div
          class="subcontent-text"
          v-html="subElement.text"
          :key="`${index}_el2_${subElement.title}_${subElement.text}`"
        ></div>
        <div
          v-if="!!subElement.showSurveyList"
          :key="`${index}_el3_${subElement.title}_${subElement.text}`"
        >
          <div class="country-listing country-listing-header">
            <div class="country-name">Country</div>
            <div class="country-link">Link</div>
            <div class="country-frame">Overlay</div>
          </div>
          <div v-for="(survey) in surveyList" class="country-listing" :key="survey.key">
            <div class="country-name">{{survey.label}}</div>
            <div class="country-link">{{ survey.link }}</div>
            <div class="country-frame">
              <textarea readonly v-html="`${survey.overlay}`" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import trans from '~/src/translations/en.json';
export default {
  data() {
    return { trans };
  },
  props: {
    element: Object,
    mapping: Object,
    config: Object,
    index: String
  },
  computed: {
    surveyList() {
      const surveys = [];
      Object.keys(this.trans.countryList).forEach(country => {
        if (country === 'EUROPE' || country === 'RU') return;

        if (!!this.mapping.languages[country]) {
          Object.keys(this.mapping.languages[country]).forEach(lang => {
            let key = `${country}-${this.mapping.languages[country][lang].language.toUpperCase()}`;
            let link = `https://covid19watch.net/survey/${country.toUpperCase()}/${
              this.mapping.languages[country][lang].postfix
            }`;
            surveys.push({
              key: `${key}`,
              label: `${this.trans.countryList[country]} (${this.mapping.languages[country][lang].naming}) / ${this.trans.countryListLocal[key]}`,
              country: `${country}`,
              lang: this.mapping.languages[country][lang].language,
              link: link,
              overlay: `&#x3C;script&#x3E;
  (function(){
  var bg = document.createElement(&#x27;div&#x27;);
        var wrapper = document.createElement(&#x27;div&#x27;);
        var md = document.createElement(&#x27;div&#x27;);
        var close = document.createElement(&#x27;div&#x27;);
        var iframe = document.createElement(&#x27;iframe&#x27;);
        bg.style=\&#x22;position:absolute;top:0;left:0;width:100%;height:100%;z-index:50000;\&#x22;;
        wrapper.style=\&#x22;position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.6);z-index:50001;\&#x22;;
        wrapper.addEventListener(&#x27;click&#x27;,function(){document.body.removeChild(bg)});
        md.style=\&#x22;margin:0 auto;background-color:#fff;width:60%;max-width:1200px;height:100%;position:relative;z-index:50002;\&#x22;;
        iframe.src=\&#x22;${link}\&#x22;;
        iframe.style=\&#x22;width:100%;height:100%;border:0;\&#x22;;
        close.style=\&#x22;position:absolute;right:10px;top:10px;width:30px;height:30px;cursor:pointer;\&#x22;;
        close.addEventListener(&#x27;click&#x27;,function(){document.body.removeChild(bg)});
        close.innerText = &#x27;\u2573&#x27;;
        bg.appendChild(wrapper);
        bg.appendChild(md);
        md.appendChild(close);
        md.appendChild(iframe);
        document.body.appendChild(bg);
    })();
&#x3C;/script&#x3E;`
            });
          });
        } else {
          if (!!this.config.scenariosPerCountry[country]) {
            let link = `https://covid19watch.net/survey/${country.toUpperCase()}`;
            surveys.push({
              key: `${country}`,
              label: `${this.trans.countryList[country]} / ${this.trans.countryListLocal[country]}`,
              country: `${country}`,
              lang: null,
              link: link,
              overlay: `&#x3C;script&#x3E;
  (function(){
  var bg = document.createElement(&#x27;div&#x27;);
        var wrapper = document.createElement(&#x27;div&#x27;);
        var md = document.createElement(&#x27;div&#x27;);
        var close = document.createElement(&#x27;div&#x27;);
        var iframe = document.createElement(&#x27;iframe&#x27;);
        bg.style=\&#x22;position:absolute;top:0;left:0;width:100%;height:100%;z-index:50000;\&#x22;;
        wrapper.style=\&#x22;position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.6);z-index:50001;\&#x22;;
        wrapper.addEventListener(&#x27;click&#x27;,function(){document.body.removeChild(bg)});
        md.style=\&#x22;margin:0 auto;background-color:#fff;width:60%;max-width:1200px;height:100%;position:relative;z-index:50002;\&#x22;;
        iframe.src=\&#x22;${link}\&#x22;;
        iframe.style=\&#x22;width:100%;height:100%;border:0;\&#x22;;
        close.style=\&#x22;position:absolute;right:10px;top:10px;width:30px;height:30px;cursor:pointer;\&#x22;;
        close.addEventListener(&#x27;click&#x27;,function(){document.body.removeChild(bg)});
        close.innerText = &#x27;\u2573&#x27;;
        bg.appendChild(wrapper);
        bg.appendChild(md);
        md.appendChild(close);
        md.appendChild(iframe);
        document.body.appendChild(bg);
    })();
&#x3C;/script&#x3E;`
            });
          }
        }
      });
      return surveys;
    }
  }
};
</script>
