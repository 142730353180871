import BaseLayout from '~/src/components/BaseLayout';
import StartPage from '~/src/pages/StartPage';
import CountryRankingPage from '~/src/pages/CountryRankingPage';
import PartnerPage from '~/src/pages/PartnerPage';
import MethodologyPage from '~/src/pages/MethodologyPage';
import SurveyRedirect from '~/src/pages/SurveyRedirect';
import SurveyCountrySelectorPage from '~/src/pages/SurveyCountrySelectorPage';

export default [
  {
    path: '/survey/:country/:lang?',
    component: SurveyRedirect
  },
  {
    path: '/',
    component: BaseLayout,
    children: [
      { path: '', component: StartPage },
      {
        path: 'country/:country/time/:timeframe',
        component: StartPage
      },
      {
        path: 'time/:timeframe',
        component: StartPage
      },
      {
        path: 'country/:country',
        component: StartPage
      },
      {
        path: 'ranking',
        component: CountryRankingPage
      },
      {
        path: 'ranking/time/:timeframe',
        component: CountryRankingPage
      },
      {
        path: 'partner',
        component: PartnerPage
      },
      {
        path: 'methodology',
        component: MethodologyPage
      },
      {
        path: 'takePart',
        component: SurveyCountrySelectorPage
      }
    ]
  }
];
