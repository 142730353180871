<template>
  <div :style="{flex: 1}">
    <div class="questionheadline">Select instance and severity of symptoms</div>
    <div>
      <button
        v-for="level in severityLevel"
        :key="level.id"
        @click="toggleMatrixColumn(level.id)"
        :disabled="!filteredByMatrixRow"
        class="question-matrixcolumn"
        :class="{
            active: filteredByMatrixColumn === level.id,
            disabled: !filteredByMatrixRow,
            [!!filteredByMatrixRow ? `r${filteredByMatrixRow.index}` : '']: true,
            [`s${level.id}`]: true
          }"
      >{{level.label }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filteredByMatrixColumn: Number,
    filteredByMatrixRow: Object,
    matrixColumns: Array
  },
  computed: {
    severityLevel() {
      let severityLevelList = [];
      this.matrixColumns.forEach((label, index) => {
        if (index === 0) {
          severityLevelList.push({ id: index, label: 'No symptoms' });
          severityLevelList.push({ id: -1, label: 'Some Symptoms (aggregated)' });
        } else {
          severityLevelList.push({ id: index, label: label });
        }
      });

      return severityLevelList;
    }
  },
  methods: {
    toggleMatrixColumn(id) {
      this.$emit('toggleMatrixColumn', id);
    }
  }
};
</script>
