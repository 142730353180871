<template>
  <section class="about">
    <div class="headline">About the survey</div>
    <div class="about-content">
      <p>
        The information contained in this site is aggregated and provided for informational purposes only. The
        crowdsourced data is collected in accordance with all applicable European Union data privacy laws and
        appropriate research methodology, and always in a way that cannot lead to a respondent’s
        identification. Participants have confirmed that their decision to provide such information is
        voluntary and that they consent to their collection and disclosure, as per the standards of the Talk
        Privacy Policy. The same applies to all non-members of the panel that decide to take part in the
        survey. No warranty, express or implied, is given as to the accuracy of this information, and no
        liability accepted for error or omission, for how the information is used, how it is interpreted or
        what reliance is placed on it. By the very nature of the tool, the information on this website may and
        will change at any time without notice as more respondents provide data.
        <br />
        <br />
        <a
          href="https://talkonlinepanel.com?source=covid19watch"
          target="_blank"
          rel="noopener"
          class="hero-link"
          >Talk Online Panel</a
        >
        specialises in full service digital data collection, from fieldwork to programming and reporting,
        operating both online and offline. We are active in 25 markets, focusing on the German-speaking
        countries and the countries of central, eastern and southeastern Europe. Our headquarters are in
        Vienna with branch offices in Leipzig, Prague, Sofia, Athens and Zurich. Reppublika, a media tech
        start-up specialising in single-source data management and audience activation, is based in Vienna,
        Berlin and Riga.
      </p>
      <div class="about-certificates">
        <img :src="`${IMAGE_ROOT}/ISO20252.png`" alt="ISO20252" />
        <img :src="`${IMAGE_ROOT}/gdpr_compliant.png`" alt="GDPR" />
      </div>
    </div>
  </section>
</template>

<script>
import { IMAGE_ROOT } from '~/utils/constants';

export default {
  data() {
    return { IMAGE_ROOT };
  }
};
</script>
