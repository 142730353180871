<template>
  <div>
    <template v-if="!!config && !!mapping">
      <template v-for="(element, index) in trans.methodology">
        <SubPageSection :key="index" :element="element" :index="index" :mapping="mapping" :config="config" />
      </template>
    </template>
  </div>
</template>

<script>
import { IMAGE_ROOT, LANG_CODE } from '~/utils/constants';
import trans from '~/src/translations/en.json';
import SubPageSection from '~/src/components/SubPageSection';

export default {
  components: { SubPageSection },
  props: {
    config: Object,
    mapping: Object
  },
  data() {
    return {
      IMAGE_ROOT,
      LANG_CODE,
      trans
    };
  }
};
</script>
