<template>
  <section class="graphwrapper">
    <template v-for="(questionIndex, index) in chartSequence">
      <div
        :key="
          `${activeCountry === 'EUROPE' ? 'europe' : scenariosOfCountry.join('_')}_${datefilter}_${
            questions[questionIndex].code
          }_${!!scenarioDataBySequence[questionIndex]}`
        "
        :class="{ tiny: index < 3, graph: true }"
        v-if="!!scenarioDataBySequence[questionIndex]"
      >
        <p v-if="!questions[questionIndex].matrix_rows">
          {{ filterOptions.switches.find(el => el.idx === questionIndex).label }}
          <span
            class="post-text"
            v-if="index < 7 && !!filterOptions.switches.find(el => el.idx === questionIndex).postText"
          >
            <br />
            {{ filterOptions.switches.find(el => el.idx === questionIndex).postText }}
          </span>
        </p>
        <div v-else>
          <p>People who experienced</p>
          <p class="sub-label">
            Default view shows all symptoms, but you can choose to show less. Click on the symptom name in the
            chart legend below to turn its display on or off.
          </p>
        </div>
        <SingleSelectBarChart :chartData="getChartData(questions[questionIndex], questionIndex)" />
      </div>
    </template>
  </section>
</template>

<script>
import trans from '~/src/translations/en.json';
import SingleSelectBarChart from '~/src/components/SingleSelectBarChart';

export default {
  components: { SingleSelectBarChart },
  props: {
    activeCountry: String,
    questions: Array,
    scenariosOfCountry: Array,
    filterOptions: Object,
    data: Object,
    countryData: Object,
    datefilter: String,
    questionSequenceByScenario: Object
  },
  data() {
    return { chartSequence: [4, 14, 6, 13, 15, 7, 8, 9] };
  },
  computed: {
    scenarioDataBySequence() {
      if (!this.data) return [];

      const data = [];
      if (this.activeCountry === 'EUROPE') {
        if (!this.countryData[this.activeCountry] || !this.countryData[this.activeCountry][this.datefilter])
          return data;

        data.push({ a0: 0 });
        Object.keys(this.countryData[this.activeCountry][this.datefilter])
          .sort((a, b) => (+a > +b ? 1 : -1))
          .forEach((questionIndex, index) => {
            if (+questionIndex > data.length) {
              for (let i = data.length; i < +questionIndex; i++) {
                data.push(null);
              }
            }
            data.push(this.countryData[this.activeCountry][this.datefilter][questionIndex]);
          });
      } else {
        this.scenariosOfCountry.forEach(scenarioId => {
          this.questionSequenceByScenario[scenarioId].forEach(questionCode => {
            data.push(this.data[this.datefilter][scenarioId][questionCode]);
          });
        });
      }

      return data;
    }
  },
  methods: {
    getChartData(question, index) {
      const data = [];
      let isSingleSelectChart = false;
      const scenarioData = this.scenarioDataBySequence[index];

      Object.keys(scenarioData).forEach(key => {
        const answer = scenarioData[key];
        if (typeof answer !== 'object') {
          if (!question.choices) return;
          data.push({
            key,
            name: question.choices[key],
            y: answer
          });
          isSingleSelectChart = true;
        } else {
          if (!question.matrix_rows) return;

          data.push({
            key,
            name: question.matrix_rows[key],
            data: Object.keys(answer).map(rowKey => answer[rowKey])
          });
        }
      });
      return {
        data: data.sort((a, b) => (a.key > b.key ? 1 : -1)).filter(el => !!el),
        isSingleSelectChart,
        country: this.activeCountry !== 'EUROPE' ? trans.countryList[this.activeCountry] : 'Europe'
      };
    }
  }
};
</script>
