<template>
  <header class="header">
    <a href="/" class="logo"></a>
    <a
      href="https://talkonlinepanel.com?source=covid19watch"
      target="_blank"
      class="talk"
      rel="noopener"
    ></a>
    <div class="header-middle">
      <router-link class="surveyaction" to="/takePart">Click here to take part in the survey!</router-link>

      <div class="summary">
        <template v-if="!!config">
          <span>{{ config.people }}</span>&nbsp;respondents in&nbsp;
          <span>{{ config.countryCount }}</span>&nbsp;countries in the period&nbsp;
          <span>{{ dateframe }}</span>
        </template>
      </div>
    </div>
  </header>
</template>

<script>
import trans from '~/src/translations/en.json';
import { IMAGE_ROOT } from '~/utils/constants';

export default {
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    dateframe() {
      if (isNaN(this.config.timeframe.start) || isNaN(this.config.timeframe.end)) return '';

      const min = new Date(+this.config.timeframe.start);
      const max = new Date(+this.config.timeframe.end);

      if (min.getMonth() === max.getMonth()) {
        return `${min.getDate()}-${max.getDate()} ${this.trans.months[max.getMonth()]}`;
      } else {
        return `${min.getDate()} ${this.trans.months[min.getMonth()]} - ${max.getDate()} ${
          this.trans.months[max.getMonth()]
        }`;
      }
    }
  },
  data() {
    return {
      trans,
      IMAGE_ROOT
    };
  }
};
</script>
