import Vue from 'vue';

import '~/assets/main.less';

import VueRouter from 'vue-router';
import routes from '~/src/router';
Vue.use(VueRouter);

import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import mapInit from 'highcharts/modules/map';
import drilldown from 'highcharts/modules/drilldown';
import mapData from '~/src/geo/europe.geo.json';

import { BRANCH_PREFIX } from '~/utils/constants';

mapInit(Highcharts);
drilldown(Highcharts);
Highcharts.maps['europe'] = mapData;

Vue.use(HighchartsVue);

// master is stored in its own bucket at the bucketroot
// all other branches are in a seperate bucket in a subdir with the BRANCHNAME
let routerBase = '';
if (!!BRANCH_PREFIX && BRANCH_PREFIX !== 'master') {
  routerBase = `${BRANCH_PREFIX}`;
}

const router = new VueRouter({
  routes,
  base: routerBase,
  mode: 'history'
});
new Vue({
  el: '#app',
  router,
  render: h => h({ template: '<div id="app"><router-view></router-view></div>' }),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  }
});
