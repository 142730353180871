<template>
  <div v-if="!!scenario">
    <div class="questionheadline">View symptoms for...</div>

    <!-- CountrySelection needs to be a direct child of the scrolled container to make position: sticky work -->
    <CountrySelection
      @setActiveCountry="setActiveCountry"
      :changeCountry="changeCountry"
      :activeCountry="activeCountry"
      :countries="countries"
    />
    <div class="country-region-selection--overlap"></div>
    <SymptomFilters
      v-if="!!config && !!scenario"
      :filteredByMatrixRow="filteredByMatrixRow"
      :matrixRows="scenario.filterOptions.matrix_rows"
      @toggleMatrixRow="toggleMatrixRow"
    />
    <section class="filters">
      <SeverityFilters
        v-if="!!scenario"
        :matrixColumns="scenario.filterOptions.matrix_columns"
        :filteredByMatrixRow="filteredByMatrixRow"
        :filteredByMatrixColumn="filteredByMatrixColumn"
        @toggleMatrixColumn="toggleMatrixColumn"
      />
      <DateFilter @setDateFilter="setDateFilter" :datefilter="datefilter" :weeks="weeks" />
    </section>
    <Map
      v-if="!!mapData"
      :mapData="mapData"
      :mapName="mapName"
      :currentFiltering="currentFiltering"
      :mapCredits="mapCredits"
      :activeCountry="activeCountry"
      :countryMapAvailable="countryMapAvailable"
      :dataBySymptom="dataBySymptom"
      @switchCountry="setActiveCountry"
    />
    <section class="share">
      <div class="social-share">
        <a
          href="https://www.facebook.com/sharer/sharer.php?u=https://covid19watch.net"
          target="_blank"
          rel="noopener"
        >
          <img :src="`${IMAGE_ROOT}/facebook.svg`" class="social-share-image" />
        </a>
        <a
          href="https://twitter.com/share?url=https://covid19watch.net&text=%23covid19"
          target="_blank"
          rel="noopener"
        >
          <img :src="`${IMAGE_ROOT}/twitter.svg`" class="social-share-image" />
        </a>
        <a
          href="whatsapp://send?text=https://covid19watch.net"
          target="_blank"
          rel="noopener"
          class="whatsapp only-mobile"
        >
          <img :src="`${IMAGE_ROOT}/whatsapp.svg`" class="social-share-image" />
        </a>
        <a
          href="fb-messenger://share?link=https://covid19watch.net"
          target="_blank"
          rel="noopener"
          class="only-mobile"
        >
          <img :src="`${IMAGE_ROOT}/fb-messenger.svg`" class="social-share-image" />
        </a>
      </div>
    </section>
    <section class="dataarea">
      <div class="dataheader">
        <h2 class="dataheadline">
          {{ trans.symptomsPerRegion
          }}{{ activeCountry === 'EUROPE' ? 'Europe' : trans.countryList[activeCountry] }}
        </h2>
      </div>
    </section>
    <GraphSection
      v-if="!!config && !!scenario"
      :datefilter="datefilter"
      :activeCountry="activeCountry"
      :questions="scenario.questions"
      :scenariosOfCountry="config.scenariosPerCountry[activeCountry]"
      :filterOptions="scenario.filterOptions"
      :data="data"
      :countryData="countryData"
      :questionSequenceByScenario="config.questionSequenceByScenario"
    />

    <SurveyList v-if="!!config && !!mapping" :mapping="mapping" :config="config" />
    <Hero />
    <Partners />
    <About />
  </div>
</template>

<script>
import Map from '~/src/components/Map';
import SingleSelectBarChart from '~/src/components/SingleSelectBarChart';
import SymptomFilters from '~/src/components/SymptomFilters';
import SeverityFilters from '~/src/components/SeverityFilters';
import Hero from '~/src/components/Hero';
import CountrySelection from '~/src/components/CountrySelection';
import DateFilter from '~/src/components/DateFilter';
import GraphSection from '~/src/components/GraphSection';
import SurveyList from '~/src/components/SurveyList';
import Partners from '~/src/components/Partners';
import About from '~/src/components/About';

import { aggregateDataBySymptom } from '~/utils/helpers';
import { IMAGE_ROOT, LANG_CODE, CLOUDFRONT_ID, AGGREGATED_DATA } from '~/utils/constants';
import trans from '~/src/translations/en.json';

export default {
  components: {
    Hero,
    Map,
    SingleSelectBarChart,
    CountrySelection,
    DateFilter,
    SeverityFilters,
    SymptomFilters,
    GraphSection,
    SurveyList,
    Partners,
    About
  },
  props: {
    config: Object,
    mapping: Object,
    scenario: Object
  },
  data() {
    return {
      IMAGE_ROOT,
      LANG_CODE,
      trans,
      weeks: [],
      data: null,
      countryData: {},
      datefilter: 'all',
      dateframe: null,
      currentQuestion: null,
      activeCountry: 'EUROPE',
      changeCountry: 'EUROPE',
      activeRegion: '',
      mapName: 'europe',
      filteredByMatrixRow: null,
      filteredByMatrixColumn: null,
      countryMapAvailable: true
    };
  },
  computed: {
    dataBySymptom() {
      return aggregateDataBySymptom(
        this.filteredByMatrixRow,
        this.data,
        this.config,
        this.mapping,
        this.scenario,
        this.activeCountry,
        this.filteredByMatrixColumn,
        this.datefilter
      );
    },
    countries() {
      var allCountries = [];
      if (!this.config) return allCountries;
      allCountries = Object.keys(this.config.scenariosPerCountry)
        .map(key => {
          return { key, label: this.trans.countryList[key] };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .filter(el => !!el.label && this.config.ignoreCountries.indexOf(el.key.toLowerCase()) === -1);
      allCountries.unshift({ key: 'EUROPE', label: this.trans.countryList['EUROPE'] });

      return allCountries;
    },
    mapCredits() {
      return this.activeCountry !== 'EUROPE' ? trans.countryList[this.activeCountry] : 'Europe';
    },
    currentFiltering() {
      if (!!this.filteredByMatrixRow) {
        return `${this.scenario.filterOptions.matrix_rows
          .find(
            row =>
              row.idx === this.filteredByMatrixRow.questionIndex &&
              row.value === this.filteredByMatrixRow.rowKey
          )
          .label.toUpperCase()} - ${
          this.filteredByMatrixColumn === 0 ? 'No symptoms' : `Severity${this.filteredByMatrixColumn}`
        }`;
      }
      return '';
    },
    filteredMapData() {
      if (!this.filteredByMatrixRow) return null;

      let scenariosOfCountry;
      let useCountryData = false;
      if (this.activeCountry !== 'EUROPE' && this.canDrillDown(this.activeCountry)) {
        scenariosOfCountry = [this.activeCountry];
        useCountryData = true;
      } else {
        scenariosOfCountry = Object.keys(this.config.scenariosPerCountry).filter(
          el => this.config.ignoreCountries.indexOf(el.toLowerCase()) === -1
        );
      }
      const scenarioData = scenariosOfCountry.map(key => {
        const scenarioId = this.config.scenariosPerCountry[key][0];
        const questionCode = this.config.questionSequenceByScenario[scenarioId][
          this.filteredByMatrixRow.questionIndex
        ];
        let yes;
        if (useCountryData) {
          if (this.filteredByMatrixColumn === -1) {
            yes = this.countryData[key][this.datefilter][scenarioId][questionCode][
              this.filteredByMatrixRow.rowKey
            ][`a0`];
            return Object.keys(yes).map(regionKey => {
              let value = 100 - yes[regionKey];
              const label = `<div>${value.toFixed(
                2
              )}% reported symptoms<br /><span style="font-size: .8rem;">aggregated (Severity 1-5)</span><br /><br /></div>`;
              return {
                'hc-key': regionKey.toLowerCase(),
                value: value,
                label: label
              };
            });
          } else {
            yes = this.countryData[key][this.datefilter][scenarioId][questionCode][
              this.filteredByMatrixRow.rowKey
            ][`a${this.filteredByMatrixColumn}`];

            if (!yes) return [];
            return Object.keys(yes).map(regionKey => {
              const label = `<div>${yes[regionKey].toFixed(2)}% reported<br />${this.currentFiltering}</div>`;
              return {
                'hc-key': regionKey.toLowerCase(),
                value: yes[regionKey],
                label: label
              };
            });
          }
        } else {
          if (this.filteredByMatrixColumn === -1) {
            if (!this.data[this.datefilter][scenarioId]) return { 'hc-key': key.toLowerCase(), value: 0 };
            yes = this.data[this.datefilter][scenarioId][questionCode][this.filteredByMatrixRow.rowKey][`a0`];
            if (!yes) return { 'hc-key': key.toLowerCase(), value: 0.0 };
            let value = 100 - yes;
            const label = `<div>${value.toFixed(
              2
            )}% reported<br /> reported symptoms<br /><span style="font-size: .8rem;">aggregated (Severity 1-5)</span><br /><br /></div>`;
            return { 'hc-key': key.toLowerCase(), value: value, label: label };
          } else {
            if (!this.data[this.datefilter][scenarioId]) return { 'hc-key': key.toLowerCase(), value: 0 };
            yes = this.data[this.datefilter][scenarioId][questionCode][this.filteredByMatrixRow.rowKey][
              `a${this.filteredByMatrixColumn}`
            ];
            if (!yes) return { 'hc-key': key.toLowerCase(), value: 0.0 };
            const label = `<div>${yes.toFixed(2)}% reported<br />${this.currentFiltering}</div>`;
            return { 'hc-key': key.toLowerCase(), value: yes, label: label };
          }
        }
      });

      if (useCountryData) {
        return scenarioData[0];
      }
      return scenarioData;
    },
    mapData() {
      if (!!this.filteredMapData) {
        console.log('using filtered map data');
        return this.filteredMapData;
      }

      if (!this.config || !this.data) return;

      const data = [];
      let label = 'choose a filter to see a statistic';
      if (this.activeCountry !== 'EUROPE' && this.canDrillDown(this.activeCountry)) {
        const scenarioId = this.config.scenariosPerCountry[this.activeCountry][0];
        const questionCode = this.config.questionSequenceByScenario[scenarioId][0];

        if (!this.countryData[this.activeCountry][this.datefilter][scenarioId][questionCode]) return data;

        const yes = this.countryData[this.activeCountry][this.datefilter][scenarioId][questionCode]['a0'];
        Object.keys(yes).map(regionKey => {
          let symptomsReported = [];
          this.scenario.filterOptions.matrix_rows.forEach(matrixRow => {
            let value = this.countryData[this.activeCountry][this.datefilter][scenarioId][
              this.config.questionSequenceByScenario[scenarioId][matrixRow.idx]
            ][matrixRow.value]['a0'][regionKey];
            if (!value) return;
            symptomsReported.push({ name: matrixRow.label, value: 100 - value });
          });

          let symptomsString = '';
          symptomsReported
            .sort((a, b) => (a.value < b.value ? 1 : -1))
            .forEach((symptom, index) => {
              if (index < 3) {
                symptomsString += `${symptom.name}: ${symptom.value.toFixed(2)}%<br />`;
              } else {
                return;
              }
            });
          if (!symptomsReported.length) return;
          label = `<div>Top ${
            symptomsReported.length < 3 ? symptomsReported.length : 3
          } reported symptoms<br /><span style="font-size: .8rem;">(Severity 1-5)</span><br /><br />${symptomsString}<br />${
            this.currentFiltering
          }</div>`;

          data.push({
            'hc-key': regionKey.toLowerCase(),
            value: yes[regionKey],
            label: label
          });
        });
      } else {
        Object.keys(this.config.scenariosPerCountry).forEach(country => {
          this.config.scenariosPerCountry[country].forEach(scenarioId => {
            if (!this.data[this.datefilter][scenarioId]) return;
            let symptomsReported = [];
            this.scenario.filterOptions.matrix_rows.forEach(matrixRow => {
              let value = this.data[this.datefilter][scenarioId][
                this.config.questionSequenceByScenario[scenarioId][matrixRow.idx]
              ][matrixRow.value]['a0'];
              symptomsReported.push({ name: matrixRow.label, value: 100 - value });
            });

            let symptomsString = '';
            symptomsReported
              .sort((a, b) => (a.value < b.value ? 1 : -1))
              .forEach((symptom, index) => {
                if (index < 3) {
                  symptomsString += `${symptom.name}: ${(+symptom.value || 0).toFixed(2)}%<br />`;
                } else {
                  return;
                }
              });
            label = `<div>Top ${
              symptomsReported.length < 3 ? symptomsReported.length : 3
            } reported symptoms<br /><span style="font-size: .8rem;">(Severity 1-5)</span><br /><br />${symptomsString}<br />${
              this.currentFiltering
            }</div>`;
            if (
              !this.data[this.datefilter][scenarioId][this.config.questionSequenceByScenario[scenarioId][0]]
            )
              return;
            data.push({
              'hc-key': country.toLowerCase(),
              value: this.data[this.datefilter][scenarioId][
                this.config.questionSequenceByScenario[scenarioId][0]
              ]['a0'],
              label: label,
              drilldown: true
            });
          });
        });
      }

      return data;
    }
  },
  methods: {
    getData() {
      fetch(`https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/aggregatedCounts.json`)
        .then(r => r.json())
        .then(data => {
          this.countryData['EUROPE'] = { all: data.all, ...data.weeks };
          this.weeks = Object.keys(data.weeks);
        });
      fetch(
        `https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/aggregatedCountsByScenario.json`
      )
        .then(r => r.json())
        .then(data => {
          this.data = { all: data.all, ...data.weeks };
        })
        .catch(console.error);
    },
    setDateFilter(filter) {
      this.datefilter = filter;
      this.switchPath(this.activeCountry);
    },
    loadCountryData(country) {
      if (country === 'EUROPE') {
        this.mapName = country.toLowerCase();
        this.activeCountry = country;
      } else if (!this.countryData[country]) {
        fetch(
          `https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/province/${country}.json`
        )
          .then(r => r.json())
          .then(data => {
            this.countryData[country] = { all: data.all, ...data.weeks };
            this.activeCountry = country;
            this.activeRegion = '';
            if (this.canDrillDown(country)) {
              this.mapName = country.toLowerCase();
            } else {
              this.mapName = 'europe';
            }
          })
          .catch(data => {
            this.activeCountry = country;
            this.activeRegion = '';
            if (this.canDrillDown(country)) {
              this.mapName = country.toLowerCase();
            } else {
              this.mapName = 'europe';
            }
          });
      } else {
        this.activeCountry = country;
        this.activeRegion = '';
        if (this.canDrillDown(country)) {
          this.mapName = country.toLowerCase();
        } else {
          this.mapName = 'europe';
        }
      }
    },
    canDrillDown(country) {
      this.countryMapAvailable =
        this.mapping.province[country] !== undefined && this.mapping.province[country][0].drilldownEnabled;
      return this.countryMapAvailable;
    },
    toggleMatrixRow({ questionIndex, rowKey, loopIndex }) {
      if (
        !!this.filteredByMatrixRow &&
        this.filteredByMatrixRow.questionIndex === questionIndex &&
        this.filteredByMatrixRow.rowKey === rowKey
      ) {
        this.filteredByMatrixRow = null;
        this.filteredByMatrixColumn = null;
      } else {
        this.filteredByMatrixColumn = this.filteredByMatrixColumn || -1;
        this.filteredByMatrixRow = { questionIndex, rowKey, index: loopIndex + 1 };
      }
    },
    toggleMatrixColumn(column) {
      this.filteredByMatrixColumn = column;
    },

    setActiveCountry(country) {
      const upperCasedCountry = country.toUpperCase();
      this.changeCountry = upperCasedCountry;
    },
    switchPath(country) {
      const countryPath = this.changeCountry !== 'EUROPE' ? `/country/${this.changeCountry}` : '';
      const datePath = this.datefilter !== 'all' ? `/time/${this.datefilter}` : '';
      let path = `${countryPath}${datePath}` || '/';

      this.$router.push({ path });
    }
  },
  created() {
    if (!!this.$route.params && !!this.$route.params.country) {
      this.changeCountry = this.$route.params.country.toUpperCase();
    }

    if (!!this.$route.params && !!this.$route.params.timeframe) {
      this.datefilter = this.$route.params.timeframe;
    }
    this.getData();
  },
  watch: {
    changeCountry(val) {
      this.switchPath();
      this.loadCountryData(val);
    }
  }
};
</script>
