<template>
  <section class="partners">
    <div class="headline">Thanks to our partners</div>
    <div>
      <a class="partner-logo" href="https://reppublika.com" target="_blank">
        <img :src="`${IMAGE_ROOT}/Reppublika_logo.svg`" alt="Reppublika Logo" />
      </a>
      <a class="partner-logo" href="https://www.datacollect.cz" target="_blank">
        <img :src="`${IMAGE_ROOT}/DC_noclaim.png`" alt="Datacollect Logo" />
      </a>
    </div>
    <div class="partners-info">
      Interested in featuring the survey on your website so your readers can participate? Get in touch at
      <a
        href="mailto:covid19watch@talkonlinepanel.com"
      >covid19watch@talkonlinepanel.com</a>
    </div>
  </section>
</template>

<script>
import { IMAGE_ROOT } from '~/utils/constants';

export default {
  data() {
    return { IMAGE_ROOT };
  }
};
</script>
