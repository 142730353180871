<template>
  <section class="hero">
    The <router-link to="/" class="hero-link">covid19watch.net</router-link> website is a
    <b>crowdsourced, self-updating</b> dashboard tool that collects and visualises data related to the
    symptoms of the COVID19 coronavirus during the spring 2020 outbreak in Europe. It has been jointly
    developed by the research community
    <a href="https://talkonlinepanel.com?source=covid19watch" target="_blank" rel="noopener" class="hero-link"
      >Talk Online Panel</a
    >
    and the data analytics company
    <a href="https://reppublika.com" target="_blank" rel="noopener" class="hero-link">Reppublika</a>, with the
    aim to facilitate both collection and presentation of data: the emphasis is on <b>symptoms</b> because
    that data set is easily <b>self-reported</b>, while official testing is not always readily available and
    takes time to arrange and process. By making data accessible via the dashboard, we hope to help health
    <b>authorities identify vulnerable areas and populations</b> that may require assistance - and also to
    <b>increase public awareness</b> and individual civic responsibility. The answers are visualised on the
    <b>interactive map</b> as a percentage of the total people who took the survey in that country. For more
    details, scroll down the page to find the answer set for each country in <b>chart form</b>.
  </section>
</template>

<script>
import trans from '~/src/translations/en.json';
export default {
  data() {
    return { trans };
  }
};
</script>
