export function aggregateDataBySymptom(
  filteredByMatrixRow,
  data,
  config,
  mapping,
  scenario,
  activeCountry,
  filteredByMatrixColumn,
  datefilter
) {
  if (!filteredByMatrixRow || !data || !config || !mapping || !scenario) return null;

  let scenariosOfCountry;
  if (
    activeCountry !== 'EUROPE' &&
    !!mapping &&
    mapping.province[activeCountry] !== undefined &&
    mapping.province[activeCountry][0].drilldownEnabled
  ) {
    scenariosOfCountry = [activeCountry];
    return null;
  } else {
    scenariosOfCountry = Object.keys(config.scenariosPerCountry).filter(
      el => config.ignoreCountries.indexOf(el.toLowerCase()) === -1
    );
  }
  const scenarioData = scenariosOfCountry.map(key => {
    const scenarioId = config.scenariosPerCountry[key][0];
    const questionCode = config.questionSequenceByScenario[scenarioId][filteredByMatrixRow.questionIndex];
    let yes;
    if (filteredByMatrixColumn === -1) {
      if (!data[datefilter][scenarioId]) return { country: key, value: 0 };
      yes = data[datefilter][scenarioId][questionCode][filteredByMatrixRow.rowKey][`a0`];
      if (!yes) return { country: key, value: 0.0 };
      let value = (100 - yes).toFixed(2);
      return { country: key, value };
    } else {
      if (!data[datefilter][scenarioId]) return { country: key, value: 0 };
      yes =
        data[datefilter][scenarioId][questionCode][filteredByMatrixRow.rowKey][`a${filteredByMatrixColumn}`];
      if (!yes) return { country: key, value: 0.0 };
      return { country: key, value: yes.toFixed(2) };
    }
  });

  return scenarioData.sort((a, b) => (+a.value > +b.value ? -1 : 1));
}
