<template>
  <section v-if="!!mapping && !!config" class="bepart bepart-standalone">
    <h2 class="bepart-heading">Click on your country to participate in the survey</h2>
    <div class="bepartlinks">
      <template v-for="survey in surveyList">
        <a
          :key="survey.key"
          :href="`${survey.link}`"
          target="_blank"
          class="bepartlink"
          rel="noopener"
          :class="{ [`link-${survey.country.toLowerCase()}`]: true }"
        >{{ survey.label }}</a>
      </template>
    </div>
  </section>
</template>

<script>
import { IMAGE_ROOT, LANG_CODE, CLOUDFRONT_ID, AGGREGATED_DATA } from '~/utils/constants';
import trans from '~/src/translations/en.json';

export default {
  props: {
    config: Object,
    mapping: Object,
    scenario: Object
  },
  data() {
    return {
      IMAGE_ROOT,
      LANG_CODE,
      trans,
      data: null,
      countryData: {},
      datefilter: 'all',
      dateframe: null,
      currentQuestion: null,
      activeCountry: 'EUROPE',
      changeCountry: 'EUROPE',
      activeRegion: '',
      mapName: 'europe',
      showFilters: false,
      filteredBySwitch: null,
      filteredByMatrixRow: null,
      filteredByMatrixColumn: null,
      countryMapAvailable: true
    };
  },
  methods: {
    getData() {
      fetch(`https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/aggregatedCounts.json`)
        .then(r => r.json())
        .then(data => {
          this.countryData['EUROPE'] = data;
        });
      fetch(
        `https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/aggregatedCountsByScenario.json`
      )
        .then(r => r.json())
        .then(data => {
          this.data = data;
        })
        .catch(console.error);
    },
    loadCountryData(country) {
      if (country === 'EUROPE') {
        this.mapName = country.toLowerCase();
        this.activeCountry = country;
      } else if (!this.countryData[country]) {
        fetch(
          `https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/province/${country}.json`
        )
          .then(r => r.json())
          .then(data => {
            this.countryData[country] = data;
            this.activeCountry = country;
            this.activeRegion = '';
            if (this.canDrillDown(country)) {
              this.mapName = country.toLowerCase();
            } else {
              this.mapName = 'europe';
            }
          })
          .catch(data => {
            this.activeCountry = country;
            this.activeRegion = '';
            if (this.canDrillDown(country)) {
              this.mapName = country.toLowerCase();
            } else {
              this.mapName = 'europe';
            }
          });
      } else {
        this.activeCountry = country;
        this.activeRegion = '';
        if (this.canDrillDown(country)) {
          this.mapName = country.toLowerCase();
        } else {
          this.mapName = 'europe';
        }
      }
    },
    storeMapping(mapping) {
      this.mapping = mapping;
    },
    storeConfig(config) {
      this.config = config;
    }
  },
  created() {
    this.getData();
  },
  computed: {
    surveyList() {
      const surveys = [];
      Object.keys(this.trans.countryList).forEach(country => {
        if (country === 'EUROPE' || country === 'RU') return;

        if (!!this.mapping.languages[country]) {
          Object.keys(this.mapping.languages[country]).forEach(lang => {
            let key = `${country}-${this.mapping.languages[country][lang].language.toUpperCase()}`;
            let link = `https://covid19watch.net/survey/${country.toUpperCase()}/${
              this.mapping.languages[country][lang].postfix
            }`;
            surveys.push({
              key: `${key}`,
              label: `${this.trans.countryListLocal[key].toUpperCase()}`,
              country: `${country}`,
              lang: this.mapping.languages[country][lang].language,
              link: link
            });
          });
        } else {
          if (!!this.config.scenariosPerCountry[country]) {
            let link = `https://covid19watch.net/survey/${country.toUpperCase()}`;
            surveys.push({
              key: `${country}`,
              label: this.trans.countryListLocal[country].toUpperCase(),
              country: `${country}`,
              lang: null,
              link: link
            });
          }
        }
      });
      return surveys;
    }
  }
};
</script>
