<template>
  <section class="questionselection">
    <div class="questionheadline">Show people who said they experienced...</div>
    <div class="matrixrows">
      <button
        v-for="(filter, index) in matrixRows"
        :key="`${filter.idx}_${filter.value}`"
        @click="toggleMatrixRow(filter.idx, filter.value, index)"
        class="question-matrixrow"
        :class="{
            [`r${index + 1}`]: true,
            active:
              !!filteredByMatrixRow &&
              filteredByMatrixRow.questionIndex === filter.idx &&
              filteredByMatrixRow.rowKey === filter.value
          }"
      >{{ filter.label }}</button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    matrixRows: Array,
    filteredByMatrixRow: Object
  },
  methods: {
    toggleMatrixRow(questionIndex, rowKey, loopIndex) {
      this.$emit('toggleMatrixRow', { questionIndex, rowKey, loopIndex });
    }
  }
};
</script>
