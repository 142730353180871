<template>
  <div>
    <div class="questionheadline">Select timeframe:</div>
    <select v-model="currentWeek" @change="setDateFilter" class="mobile-country-selection">
      <option
        v-for="week in ['all', ...weeks]"
        :key="week"
        :value="week"
      >{{week !== 'all' ? `Weeks: ${week}` : 'All'}}</option>
    </select>
  </div>
</template>

<script>
import trans from '~/src/translations/en.json';

export default {
  data() {
    return { trans, currentWeek: 'all' };
  },
  props: {
    datefilter: { type: String, default: 'all' },
    weeks: Array
  },
  methods: {
    setDateFilter() {
      this.$emit('setDateFilter', this.currentWeek);
    }
  },
  mounted() {
    this.currentWeek = this.datefilter;
  }
};
</script>
