<template>
  <div>
    <Header :config="config" />
    <router-view v-if="!!scenario" :config="config" :mapping="mapping" :scenario="scenario" />
    <Footer />
  </div>
</template>

<script>
import { CLOUDFRONT_ID, AGGREGATED_DATA } from '~/utils/constants';
import Header from '~/src/components/Header';
import Footer from '~/src/components/Footer';

export default {
  data() {
    return {
      config: null,
      mapping: null,
      scenario: null
    };
  },
  components: { Header, Footer },
  created() {
    fetch(`https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/scenario.json`)
      .then(r => r.json())
      .then(data => {
        this.scenario = data;
      });
    fetch(`https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/config.json`)
      .then(r => r.json())
      .then(data => {
        this.config = data;
      });
    fetch(`https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/mapping.json`)
      .then(r => r.json())
      .then(data => {
        this.mapping = data;
      })
      .catch(console.error);
  }
};
</script>
