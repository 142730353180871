<template>
  <div>
    <highcharts v-if="!!chartOptions" :constructor-type="'chart'" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import trans from '~/src/translations/en.json';

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export default {
  components: {},
  props: {
    chartData: Object
  },
  data() {
    return {
      chartOptions: null
    };
  },
  mounted() {
    this.chartOptions = this.getChartOptions();
  },
  methods: {
    getChartOptions() {
      return {
        chart: {
          type: this.chartData.isSingleSelectChart ? 'pie' : 'column',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        colors: this.chartData.isSingleSelectChart
          ? shuffle(['#e62159', '#f6be00', '#ff8650', '#b68bbc', '#f086a0', '#519480', '#2682a0'])
          : ['#e62159', '#f6be00', '#ff8650', '#b68bbc', '#f086a0', '#519480', '#2682a0'],
        legend: this.chartData.isSingleSelectChart
          ? {}
          : {
              align: 'left',
              verticalAlign: 'top',
              alignColumns: false
            },

        xAxis: {
          categories: this.chartData.isSingleSelectChart
            ? ['']
            : ['No Symptoms', 'Severity 1', 'Severity 2', 'Severity 3', 'Severity 4', 'Severity 5'],
          crosshair: true
        },
        yAxis: this.chartData.isSingleSelectChart
          ? undefined
          : {
              title: {
                text: 'percentage'
              },
              max: 100
            },
        credits: {
          enabled: true,
          href: 'https://covid19watch.net',
          text: `Source: covid19watch.net, CAWI, Date: ${new Date().getDate()}. ${
            trans.months[new Date().getMonth()]
          } ${new Date().getFullYear()}, Data: ${this.chartData.country || 'Europe'}`
        },
        tooltip: this.chartData.isSingleSelectChart
          ? {
              backgroundColor: '#fff',
              headerFormat: ' ',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{point.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
            }
          : {
              backgroundColor: '#fff',
              headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.2f}%</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
            },
        plotOptions: this.chartData.isSingleSelectChart
          ? {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            }
          : {
              column: {
                pointPadding: 0.2,
                borderWidth: 0
              }
            },
        series: this.chartData.isSingleSelectChart ? [{ data: this.chartData.data }] : this.chartData.data
      };
    }
  },
  watch: {
    chartData() {
      this.chartOptions = this.getChartOptions();
    }
  }
};
</script>
<style scoped>
.map {
  min-height: 500px;
}
</style>
