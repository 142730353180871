<template>
  <div>
    <div>You will be redirected</div>
  </div>
</template>

<script>
import { CLOUDFRONT_ID } from '~/utils/constants';

export default {
  data() {
    return {
      config: null,
      mapping: null,
      country: null,
      lang: null,
      redirectURL: null
    };
  },

  created() {
    if (!!this.$route.params && !!this.$route.params.country) {
      this.country = this.$route.params.country.toUpperCase();
    }
    if (!!this.$route.params && !!this.$route.params.lang) {
      this.lang = this.$route.params.lang.toLowerCase();
    }
    const configPromise = fetch(
      `https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/config.json`
    )
      .then(r => r.json())
      .then(data => {
        this.config = data;
      });
    const mappingPromise = fetch(
      `https://${CLOUDFRONT_ID}.cloudfront.net/${AGGREGATED_DATA}/Corona_all/mapping.json`
    )
      .then(r => r.json())
      .then(data => {
        this.mapping = data;
      })
      .catch(console.error);
    Promise.all([configPromise, mappingPromise]).then(this.redirectToSurvey);
  },
  methods: {
    redirectToSurvey() {
      if (!this.config || !this.country) return;

      let currentURL = null;
      if (!!this.config.scenariosPerCountryRedirect[this.country]) {
        currentURL = `https://surveys.talkonlinepanel.com/iv/${this.config.redirectGroup}/${this.country}/${
          this.config.scenariosPerCountryRedirect[this.country]
        }`;
      }

      let currentPostfix = '';
      if (!!this.lang && !!this.mapping.languages[this.country]) {
        this.mapping.languages[this.country].forEach(langObject => {
          if (langObject.language === this.lang) {
            currentPostfix = langObject.postfix;
          }
        });
        if (currentPostfix !== '') {
          currentURL = `https://surveys.talkonlinepanel.com/iv/${this.config.redirectGroup}/${this.country}/${
            this.config.scenariosPerCountryRedirect[this.country]
          }/${currentPostfix}`;
        }
      }

      if (!currentURL) return;
      this.redirectURL = currentURL;
      window.location.href = currentURL;
    }
  }
};
</script>
